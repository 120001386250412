import styled, { css } from 'styled-components/macro'
import React from 'react'
import { rem } from 'polished'
import { VerticalMarginProps, marginsCss } from '../../theme/mixins/margins'

interface Props extends VerticalMarginProps {
  src: string | null
  className?: string
  width?: number
  height?: number
  responsive?: boolean
  ratio?: '4by3' | '16by9' | '1by1' | '3by4'
  rounded?: boolean
  style?: React.CSSProperties
  center?: boolean
}

export const Image: React.FC<Props> = ({ src, className, style, ...props }) => {
  if (!src) {
    return null
  }

  return (
    <Wrapper className={className} {...props} style={style}>
      <InnerImage src={src} loading="lazy" {...props} />
    </Wrapper>
  )
}

const Wrapper = styled.div<Partial<Props>>`
  ${marginsCss};
  line-height: 0;

  ${p =>
    p.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

  ${p =>
    p.width &&
    p.height &&
    css`
      position: relative;
      display: block;
      width: ${rem(p.width)};
      height: ${rem(p.height)};
    `}

  border-radius: ${p => p.rounded && '999999px'};

  ${p =>
    p.ratio &&
    css`
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;

      &::before {
        display: block;
        content: "";

        padding-top: ${() => {
          switch (p.ratio) {
            case '4by3':
              return '75%'
            case '3by4':
              return '133.33%'
            case '16by9':
              return '56.25%'
            case '1by1':
              return '100%'
            default:
              return null
          }
        }}
  `}
`

const InnerImage = styled.img<Partial<Props> & { loading: string }>`
  ${p =>
    (p.ratio || (p.width && p.height)) &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    `}

  ${p =>
    p.responsive &&
    css`
      max-width: 100%;
      height: auto;
    `}
`
