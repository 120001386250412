import styled from 'styled-components/macro'
import { sizes } from '../../theme'
import rem from 'polished/lib/helpers/rem'
import { font } from '../../theme/mixins/font'

export const HtmlContent = styled.div`
  p {
    margin-bottom: ${rem(sizes.size6)};
  }

  h2,
  h3,
  h4 {
    font-weight: 400;
  }

  h2 {
    ${font.h3};
    margin-top: ${rem(sizes.size7)};
    margin-bottom: ${rem(sizes.size5)};
  }

  h3 {
    ${font.h4};
    margin-top: ${rem(sizes.size7)};
    margin-bottom: ${rem(sizes.size5)};
  }

  h4 {
    ${font.h5};
    margin-top: ${rem(sizes.size7)};
    margin-bottom: ${rem(sizes.size5)};
  }

  ul {
    list-style: square;
    padding-left: ${rem(sizes.size5)};
    margin-bottom: ${rem(sizes.size4)};
  }

  li {
    margin-bottom: ${rem(sizes.size2)};
  }

  td {
    padding: ${rem(sizes.size2)};
  }

  b,
  strong {
    font-weight: bold;
  }
`
