import React from 'react'

interface Error {
  message: string
}

interface Props {
  errors: Error[]
}

export const GraphqlErrorList: React.FC<Props> = ({ errors }) => (
  <div>
    <h1>GraphQL Error</h1>
    {errors.map(error => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
)
